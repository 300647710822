import React from 'react';
import styled from 'styled-components';
import { PageContainer } from 'src/modules/layout';

const S = {
  PageContainer: styled(PageContainer)`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: cetner;
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
};

export default function EspaPage({ transitionStatus }) {
  return (
    <S.PageContainer transitionStatus={transitionStatus}>
      <S.Image src="https://res.cloudinary.com/sermaidis-cloudinary/image/upload/v1632291104/ATT124_PRODIAGRAFES_SITE_DIKAIOUXOU_gbcr5e.jpg" />
    </S.PageContainer>
  );
}
